import React, { useState } from 'react';
import {
  Stack, Text, FontWeights, IStackTokens, IStackStyles, ITextStyles, PrimaryButton, SelectionMode,
  Modal, getTheme, mergeStyleSets, TextField, mergeStyles, Separator, IDropdownOption, Dropdown, DetailsListLayoutMode, Link,
  MessageBar, MessageBarType, MessageBarButton
} from '@fluentui/react';
import { EditableGrid, IColumnConfig, EventEmitter, EventType } from 'fluentui-editable-grid';
import logo from './FAANG-logo.jpeg';
import './App.css';
import { useBoolean } from '@fluentui/react-hooks';
import axios from 'axios';
import { contractorSchema, clientSchema } from './schemas';
const Validator = require('jsonschema').Validator;
const validator = new Validator();

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const verticalStyle = mergeStyles({
  padding: "0 20px"
});

const stackStyles: Partial<IStackStyles> = {
  root: {
    height: "auto",
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
    minWidth: "768px",
    padding: '20px 50px 20px 50px',
  },
};
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    "min-width": '768px',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLarge,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
    alignItems: 'start',
  },
  body: {
    flex: '10 10 auto',
  },
  form: {
    flex: '10 10 auto',
    padding: '20px 50px 20px 50px',
  },
  body2: {
    "flex-direction": "column",
    "width": "100%",
    flex: '10 10 auto'
  },
  table: {
    width: "100%",
    height: '100%',
  }
});

const companyDropdownOptions = [
  { key: 'Google', text: 'Google' },
  { key: 'Amazon', text: 'Amazon' },
  { key: 'Meta', text: 'Meta' },
  { key: 'Microsoft', text: 'Microsoft' },
  { key: 'Apple', text: 'Apple' }
];

const levelDropdownOptions = [
  { key: 'Senior', text: 'Senior' },
  { key: 'Principal', text: 'Principal' },
  { key: 'Staff', text: 'Staff' },
  { key: 'SeniorStaff', text: 'Senior Staff' },
  { key: '3', text: '3' },
  { key: '4', text: '4' },
  { key: '5', text: '5' },
  { key: '6', text: '6' },
  { key: '7', text: '7' },
  { key: '8', text: '8' }
];

const titleDropdownOptions = [
  { key: 'SoftwareEngineer', text: 'Software Engineer' },
  { key: 'Data scientist', text: 'Data scientist' },
  { key: 'ProgramManager', text: 'Program Manager' },
  { key: 'Project Manager', text: 'Project Manager' },
  { key: 'UX Designer', text: 'UX Designer' }
];

export interface ICandidateList {
  name: string;
  company: string;
  title: string;
  level: number;
  yoe: number;
  skills: string;
  rate: number;
  hours: number;
  link?: string;
}

export interface ICandidateState {
  name: string;
  company: string;
  title: string;
  level: string | undefined;
  alias: string;
  email: string;
  link?: string;
  yoe: number;
  skills: string;
  rate: number;
  hours: number;
}

export interface IClientState {
  name: string;
  company: string;
  hourlyBudget: number;
  totalBudget: number;
  email: string;
  description: string;
}

const CandidateInitialState: ICandidateState = {
  name: '',
  company: '',
  title: '',
  level: undefined,
  alias: '',
  email: '',
  yoe: NaN,
  skills: '',
  rate: NaN,
  hours: NaN
}

const ClientInitialState: IClientState = {
  name: '',
  company: '',
  hourlyBudget: NaN,
  totalBudget: NaN,
  email: '',
  description: '',
}

const candidateListInit: ICandidateList[] = []

export const App: React.FunctionComponent = () => {

  const columnsInit: IColumnConfig[] = [
    {
      key: 'name',
      name: 'name',
      text: 'name',
      minWidth: 100,
      editable: true,
      dataType: 'string',
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
    },
    {
      key: 'company',
      name: 'company',
      text: 'company',
      editable: true,
      dataType: 'string',
      minWidth: 80,
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      dropdownValues: companyDropdownOptions
    },
    {
      key: 'title',
      name: 'title',
      text: 'title',
      minWidth: 100,
      editable: true,
      dataType: 'string',
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      dropdownValues: titleDropdownOptions
    },
    {
      key: 'level',
      name: 'level',
      text: 'level',
      minWidth: 60,
      editable: true,
      dataType: 'string',
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      dropdownValues: levelDropdownOptions
    },
    {
      key: 'yoe',
      name: 'yoe',
      text: 'yoe',
      editable: true,
      dataType: 'string',
      minWidth: 30,
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
    },
    {
      key: 'skills',
      name: 'skills',
      text: 'skills',
      editable: true,
      dataType: 'string',
      minWidth: 350,
      // isMultiline: true,
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true
    },
    {
      key: 'rate',
      name: 'rate',
      text: 'rate',
      editable: true,
      dataType: 'string',
      minWidth: 40,
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
    },
    {
      key: 'hours',
      name: 'hours',
      text: 'hours',
      editable: true,
      dataType: 'string',
      minWidth: 40,
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true
    },
    // {
    //   key: 'link',
    //   name: 'link',
    //   text: 'link',
    //   editable: true,
    //   dataType: 'string',
    //   minWidth: 150,
    //   applyColumnFilter: true,
    //   includeColumnInExport: true,
    //   includeColumnInSearch: true,
    // },
    {
      key: 'email',
      name: 'email',
      text: 'email',
      editable: true,
      dataType: 'string',
      minWidth: 150,
      applyColumnFilter: true,
      includeColumnInExport: true,
      includeColumnInSearch: true
    }
  ];

  const [isCandidateModalOpen, { setTrue: showCandidateModal, setFalse: hideCandidateModal }] = useBoolean(false);
  const [isClientModalOpen, { setTrue: showClientModal, setFalse: hideClientModal }] = useBoolean(false);
  const [columns, setColumns] = useState(columnsInit);
  const [candidateList, setCandidateList] = useState<ICandidateList[]>(candidateListInit);
  const [candidateState, setCandidateState] = useState<ICandidateState>(CandidateInitialState);
  const [clientState, setClientState] = useState<IClientState>(ClientInitialState);

  React.useEffect(() => {
    async function getData() {
      const result = await axios.get('/api/contractors');
      if (result.data.length > 0) {
        setCandidateList(result.data);
      };
    }
    getData();
  }, []);

  return (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
      <img className="App-logo" src={logo} alt="FAANGC2C logo" />
      <Text variant="xxLarge" styles={boldStyle}>
        Hire highly skilled candidates from top tech companies
      </Text>
      <Text variant="large">
        Whether you are a business looking for developer specialized in a tech stack or you need to consult a domain expert for your personal project.
        <br />You can find the best candidates with verified work experience from FAANG or a company of similar prestige at FAANGC2C.

      </Text>
      <div className={contentStyles.body2}>
        <TextField placeholder='Search keyword' onChange={(event) => EventEmitter.dispatch(EventType.onSearch, event)} />
        <EditableGrid
          id={1}
          columns={columns}
          items={candidateList}
          className={contentStyles.table}
          height={'300px'}
          width={'100%'}
          enableExport={true}
          enableColumnFilters={true}
          enableColumnFilterRules={true}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
        />
      </div>
      <div>
        <Stack horizontal tokens={stackTokens} horizontalAlign="center" styles={{ root: { marginTop: "25px" } }}>
          <PrimaryButton text="I'm a candidate" onClick={showCandidateModal} />
          <PrimaryButton text="I'm a client" onClick={showClientModal} />
        </Stack>
      </div>
      <div>
        <Text variant="xxLarge" styles={boldStyle}>
          FAQ
        </Text>
      </div>
      <div>
        <Stack horizontal horizontalAlign="center" style={{ padding: "20px", textAlign: "left" }}>
          <Stack verticalFill horizontalAlign='start' styles={{ root: { "flex": '1 1 0px' } }}>
            <Text className={contentStyles.heading}>
              What is C2C?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              C2C, which stands for Corp-to-Corp is a business relationship between two corporations, where they contract with each other to provide services. In this type of arrangement, the corporations act as independent entities rather than one hiring the other as an employee. </Text>
            <Text className={contentStyles.heading}>
              What are the benefits for C2C as a contractor?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              1. Increased earning potential: Ability to ask higher hourly rates as a contractor compared to being an employee.<br />
              2. Flexibility: The ability to choose projects and work arrangements that fit their schedules and lifestyle.<br />
              3. Diversified experience: Opportunity to work on multiple projects and gain a diverse range of experience.<br />
            </Text>
            <Text className={contentStyles.heading}>
              Why use FAANGC2C?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Whether you are looking for a second source of income and want to avoid complicated corporation setup yourself or you need some temporary income while looking for a new job. FAANGC2C is the best C2C marketplace for FAANG employees to get the highest hourly rate as a contractor.</Text>
            <Text className={contentStyles.heading}>
              I'm not from FAANG can you add me to the board?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              We are only able to verify employees from Meta, Microsoft, Google, Amazon and Apple.</Text>
            <Text className={contentStyles.heading}>
              I cannot work for my own company due to visa status. Can I still do C2C?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Yes, you will be hired as a contractor in our company, we take a transparent 10% cut plus all applicable tax withholding from the pay. </Text>
            <Text className={contentStyles.heading}>
              Do you sponsor H-1B?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Due to the duration of most contracts, we are not able to sponsor H-1B under normal circumstance. However, special arrangement can be made if you can find a long term contract. Send us an email for inquiry.</Text>
            <Text className={contentStyles.heading}>
              Do you provide training for new grad?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Yes, we can train you for FAANG interviews and supervise you on projects if you are a recent graduate still on your first year of STEM OPT.</Text>
            <Text className={contentStyles.heading}>
              How to get added on the list?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Apply using the form above and we will show your information if you agree to be hired as a contractor under our company when you find a contractor job through us.
            </Text>
          </Stack>
          <Separator vertical className={verticalStyle} />
          <Stack verticalFill horizontalAlign='start' styles={{ root: { "flex": '1 1 0px' } }}>
            <Text className={contentStyles.heading}>
              Why hire contractors from FAANGC2C?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              FAANGC2C is the C2C marketplace where you can find candidate with verified job history. We do preliminary screening to ensure every candidate on the list has worked in FAANG or a company with similar prestige. If you are satisfied with a candidate's background we can provide additional screening based on your specific project. All the interviewers will be experienced current employees specialized in the same field from top companies to ensure the candidate is qualified for your task.</Text>
            <Text className={contentStyles.heading}>
              What are the benefits for hiring Corp to Corp contractors?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              1. Cost savings: Potential reduction of costs associated with full-time employment, such as benefits, taxes and severance.<br />
              2. Access to specialized skills: Ability to access a wider pool of specialized skills and resources on a project basis.<br />
              3. Scalability: The ability to quickly scale resources up or down as needed.<br />
              4. No long-term commitment: The ability to end the arrangement when the project is completed or the need for the resource is no longer necessary.<br />
            </Text>
            <Text className={contentStyles.heading}>
              Want candidates reach out to you directly?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Fill out the client form and we will list your job on a job board visible to verified users. We want to ensure you only receive applications from high quality candidates who have worked in FAANG and companies with similar prestige. </Text>
            <Text className={contentStyles.heading}>
              How to hire a contractor from FAANGC2C?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              1. Reach out to a top candidate from the list or post your job listing to have it visible by all verified candidates.<br />
              2. Interview the candidate and negotiate compensation.<br />
              3. Send us the contract for review.<br />
              4. We will hire the candidate as a contractor and assign them to your project.
            </Text>
            <Text className={contentStyles.heading}>
              Need us to build a team for you?</Text>
            <Text styles={{ root: { textAlign: 'left', padding: '10px 5px' } }}>
              Email <Link>info@faangc2c.com</Link> with your requirements and budget.</Text>
          </Stack>
        </Stack>
      </div>
      <Stack horizontal horizontalAlign="center">
        <span>Contact: <Link>info@faangc2c.com</Link></span>
      </Stack>
      <Modal
        isOpen={isCandidateModalOpen}
        onDismiss={hideCandidateModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <Stack className={contentStyles.header}>
          <h2>
            Candidate Form
          </h2>
        </Stack>
        <Stack className={contentStyles.form}>
          {validator.validate(candidateState, contractorSchema).errors.length > 0 &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              dismissButtonAriaLabel="Close"
            >
              {validator.validate(candidateState, contractorSchema).errors[0].path[0] + ' ' + validator.validate(candidateState, contractorSchema).errors[0].message}
            </MessageBar>
          }
          <TextField label="Name" value={candidateState.name} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, name: newValue ?? '' })} />
          <Dropdown label="Company" options={companyDropdownOptions} defaultSelectedKey={candidateState.company ?? null} required onChange={(ev, option?: IDropdownOption): void => setCandidateState({ ...candidateState, company: option?.text as string })} />
          <Dropdown label="Level" options={levelDropdownOptions} defaultSelectedKey={candidateState.level ?? null} onChange={(ev, option): void => setCandidateState({ ...candidateState, level: option?.text as string })} />
          <Dropdown label="Title" options={titleDropdownOptions} defaultSelectedKey={candidateState.title ?? null} required onChange={(ev, option): void => setCandidateState({ ...candidateState, title: option?.text as string })} />
          <TextField label="Alias (For verfication only, won't be visible publicly)" value={candidateState.alias} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, alias: newValue ?? '' })} />
          <TextField label="Yoe" value={Number.isNaN(candidateState.yoe) ? '' : candidateState.yoe.toString()} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, yoe: newValue === undefined ? 0 : parseInt(newValue) })} />
          <TextField label="Skills (comma separated)" value={candidateState.skills} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, skills: newValue ?? '' })} />
          <TextField label="Email" value={candidateState.email} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, email: newValue ?? '' })} />
          <TextField label="Rate" value={Number.isNaN(candidateState.rate) ? '' : candidateState.rate.toString()} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, rate: newValue === undefined ? 0 : parseInt(newValue) })} />
          <TextField label="Hours per week" value={Number.isNaN(candidateState.hours) ? '' : candidateState.hours.toString()} required onChange={(ev, newValue): void => setCandidateState({ ...candidateState, hours: newValue === undefined ? 0 : parseInt(newValue) })} />
          <TextField label="Profile URL" value={candidateState.link} onChange={(ev, newValue): void => setCandidateState({ ...candidateState, link: newValue ?? '' })} />
        </Stack>
        <Stack horizontal horizontalAlign="space-between" className={contentStyles.form}>
          <PrimaryButton text="Apply" onClick={() => {
            const validationResult = validator.validate(candidateState, contractorSchema);
            console.log(validationResult)
            if (validationResult.errors.length == 0) {
              axios.post('/api/contractor', candidateState)
                .then(function (response) {
                  alert('Apply successful! We will contact you shortly!');
                  setCandidateState(CandidateInitialState);
                  hideCandidateModal()
                })
                .catch(function (error) {
                  alert('Apply fail! Error message: ' + error);
                });
            } else {
              alert(validationResult.errors[0].path[0] + ' ' + validationResult.errors[0].message)
            }
          }} />
          <PrimaryButton text="Cancel" onClick={hideCandidateModal} />
        </Stack>
      </Modal>
      <Modal
        isOpen={isClientModalOpen}
        onDismiss={hideClientModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <Stack className={contentStyles.header}>
          <h2>
            Client Form
          </h2>
        </Stack>
        <Stack className={contentStyles.form}>
          {validator.validate(clientState, clientSchema).errors.length > 0 &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              dismissButtonAriaLabel="Close"
            >
              {validator.validate(clientState, clientSchema).errors[0].path[0] + ' ' + validator.validate(clientState, clientSchema).errors[0].message}
            </MessageBar>
          }

          <TextField label="Name" value={clientState.name} required onChange={(ev, newValue): void => setClientState({ ...clientState, name: newValue ?? '' })} />
          <TextField label="Company" value={clientState.company} required onChange={(ev, newValue): void => setClientState({ ...clientState, company: newValue ?? '' })} />
          <TextField label="Hourly Budget" value={Number.isNaN(clientState.hourlyBudget) ? '' : clientState.hourlyBudget.toString()} required onChange={(ev, newValue): void => setClientState({ ...clientState, hourlyBudget: newValue === undefined ? 0 : parseInt(newValue) })} />
          <TextField label="Total Budget" value={Number.isNaN(clientState.totalBudget) ? '' : clientState.totalBudget.toString()} required onChange={(ev, newValue): void => setClientState({ ...clientState, totalBudget: newValue === undefined ? 0 : parseInt(newValue) })} />
          <TextField label="Contact" value={clientState.email} required onChange={(ev, newValue): void => setClientState({ ...clientState, email: newValue ?? '' })} />
          <TextField label="Project description" multiline rows={10} value={clientState.description} required onChange={(ev, newValue): void => setClientState({ ...clientState, description: newValue ?? '' })} />
        </Stack>
        <Stack horizontal horizontalAlign="space-between" className={contentStyles.form}>
          <PrimaryButton text="Submit" onClick={() => {
            const validationResult = validator.validate(clientState, clientSchema);
            if (validationResult.errors.length == 0) {
              axios.post('/api/client', clientState)
                .then(function (response) {
                  alert('Apply successful! We will contact you shortly!');
                  setClientState(ClientInitialState);
                  hideClientModal();
                })
                .catch(function (error) {
                  alert('Apply fail! Error message:' + error);
                });

            } else {
              alert(validationResult.errors[0].path[0] + ' ' + validationResult.errors[0].message)
            }

          }} />
          <PrimaryButton text="Cancel" onClick={hideClientModal} />
        </Stack>
      </Modal>
    </Stack>
  );
};
